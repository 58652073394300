<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Editar Accesos de Rol</template>
    <template #body>
      <form @submit.prevent="saveRoleAccesos">
          <div class="card-body">
            <vue-table-coincidenciascheckall
              :titleRows="titleRows"
              :headers="headers"
              :rows="rows"
              ref="vtablecheckall"
              @onCheck="onCheck"
            />
          </div>
        </form> 
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="saveRoleAccesos">
        Actualizar
      </button>
    </template>
  </vue-modal>
</template>
<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    titleRows: "Modulos",
    form: [],
    itemCopy:null,
  }),
  async mounted() {
    await this.getPermisos();
    await this.getRecursos();
    if (this.item) {
      this.itemCopy = {...this.item};
      await this.getClonarRole();
      await this.getClonar();
    }
  },
  computed: {
    // Mapeamos la data de las rows, con las rows de recursos que traemos de la DB, con la info de permisos-recursos que traemos de cada rol
    rows() {
      return this.$store.getters.recursosNoPaginado.rows.map((i)=>{
        // Buscamos la coincidencia de la row con la row de recursos-permisos
        const findCloneArray = this.$store.getters.clonearray.find((data)=> data.recurso_id == i.id)
        return {
          id: i.id,
          mask: i.recurso_name,
          // hacemos un map del array de falsos, buscando las coincidencias de permisos, para cambiarlos por true.
          check: this.falseArrayForCheck.map((x, index)=>{
          let checkWanted = findCloneArray?.permiso_id || 0
          
          for (let i=0; i < checkWanted.length; i++){
            if(checkWanted[i] === this.$store.getters.permisos.rows[index].id ){
              return x = true
            }
          }
          return x
        }),
        }
      });
    },
    // Headers de la tabla
    headers(){
      return this.$store.getters.permisos.rows.map((i)=>({
        id: i.id,
        mask: i.permiso_name,
      }));
    },
    // lo usamos para saber que cambios hicimos en la tabla
     rolClone(){
      if (this.$store.getters.clone.data === null) return null
      return this.$store.getters.clone.rows;
    },
    // Creamos un Array de falsos, para usarlo en rows(), nos ayuda a mapear la data de recursos permisos que traemos de la base de datos
    falseArrayForCheck(){
      return [...Array(this.$store.getters.permisos.count || this.$store.getters.permisos.rows.length).fill(false)]
    },
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getPermisos(){
      this.$store.dispatch("getPermisos");
    },
    getRecursos(){
      this.$store.dispatch("getRecursosNoPaginados");
    },
    getClonarRole(){
      const id = this.itemCopy.id
      this.$store
          .dispatch("getClonarEnArray", id);
    },
    getClonar(){
      const id = this.itemCopy.id
      this.$store
          .dispatch("getClonar", id);
    },
    onCheck(obj){
      const index = this.form.findIndex((x)=> (x.rowId === obj.rowId && x.headId === obj.headId))
      if (index != -1){
        this.form[index] = {...this.form[index],checked:obj.checked}
      }else{
        this.form.push(obj)
      }  
    },
    saveRoleAccesos() {
      // VAMOS A EXPLICAR COMO FUNCIONA ESTO

      const id = this.item.id
      let data = []

      // Si NO hay Accesos antiguos, pusheo los nuevos accesos con cheked TRUE
      // Sino pusheoo los Accesos antiguos
        if (!this.rolClone){
          data = this.form.filter((x)=> x.checked)
          data = data.map((x)=>({
            recurso_id: x.rowId,
            permiso_id: x.headId,
          }))  
        }else{
          data = this.rolClone
        }

        // Si HAY nuevos Accesos y si HAY viejos Accesos
        // Remuevo los Accesos con cheked FALSE y agrego los Accesos con cheked TRUE
        if (this.form.length && this.rolClone) {
          this.form = this.form.map((x)=>({
            recurso_id: x.rowId,
            permiso_id: x.headId,
            checked: x.checked
          }))
          this.form.forEach(n => {
            const index = this.rolClone.findIndex(v => v.recurso_id === n.recurso_id && v.permiso_id === n.permiso_id);
             if(index === -1){
              if (n.checked){
                data.push({recurso_id: n.recurso_id, permiso_id: n.permiso_id})
                }
              }else{
                if (!n.checked){
                  data.splice(index,1)
                }
              } 
          })
        } 
        // Si elmino todos los Accesos devuelvo null (la DB elimina los accesos al devolver null)
        // Sino mapeo los Accesos y envio a la DB
        if (!data.length){
          data = null
        }else{
          data = data.map((x)=>({
            recursoId: x.recurso_id,
            permisoId: x.permiso_id,
          }))
        }

      const form = {data}
      this.$store
          .dispatch("updateAccesos", {id,form}).then(() => {
          this.close()
        });
        this.$emit('saved')
    },
  }
};
</script>
<style></style>
