<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Crear y Heredar Permisos de Rol</template>
    <template #body>
      <form @submit.prevent="getClonarRole">
          <div class="card-body">
            <FormText
              label="Nuevo Nombre del Rol"
              icon="fa-solid fa-paragraph"
              v-model="form.role_name"
            />
          
            <FormSelect
              label="Rol Padre a Heredad Permisos"
              icon="fa-solid fa-chess"
              v-model="selOpt"
              :options="herencia"
            />
          </div>
        </form> 
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="getClonarRole">
        Crear
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
export default {
  components: {
    FormText,
    FormSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      role_name: null,
    },
    selOpt: null,
  }),
  computed: {
    herencia() {
      return this.$store.getters.rolesNoPaginado.rows.map((role)=>({
        value:role.id,
        name:role.role_name
      }));
    },
    herenciaPermisos(){
      if(this.$store.getters.clone.data === null) {return null}
      return this.$store.getters.clone.rows.map((i)=>({
        permisoId: i.permiso_id,
        recursoId: i.recurso_id
      }));
    },
    herenciaPermiosGenerales(){
      if(this.$store.getters.rolePermisosGenerales.data === null) {return null}
      return this.$store.getters.rolePermisosGenerales.rows.map((i)=>({
        permisoGeneralId: i.permiso_general_id,
      }));
    }
  },
  async mounted() {
    this.getRoles();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getRoles(){
      this.$store.dispatch("getRolesNoPaginado");
    },
    getClonarRole(){
      const id = this.selOpt
      this.$store
          .dispatch("getClonar", id)
          .then(() => {
            this.getPermisosGenerales()
        });
    },
    getPermisosGenerales(){
       const id = this.selOpt
       this.$store
          .dispatch("getRolePermisosGenerales", id)
          .then(() => {
            this.saveRoleName()
        });
    },
    saveRoleName() {
      const form = {
        role_name: this.form.role_name,
      }
      this.$store
          .dispatch("createRole", form)
          .then((res) => {
            this.pastePermisosGenerales(res);
        });
    },
    pastePermisosGenerales(res){
      const id = res
      const data = this.herenciaPermiosGenerales
      const form = {data}
      this.$store
        .dispatch("createRolePermisosGenerales", {id,form})
        .then((res) => {
            this.pasteClone(res)
        });
    },
    pasteClone(res){
      const id = res
      const data = this.herenciaPermisos
      const form = {data}
      this.$store
          .dispatch("createAccesos", {id,form})
          .then(() => {
            this.close();
        });
        this.$emit('saved')
    },
  },
};
</script>
<style></style>
