<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Editar Permisos Generales de Rol</template>
    <template #body>
      <form @submit.prevent="saveRolePG">
          <div class="card-body">
            <vue-table-permisosgenerales
              :tittleRows="tittleRows"
              :rows="rows"
              ref="vtablePG"
              @onCheck="onCheck"
            />
          </div>
        </form> 
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="saveRolePG">
        Actualizar
      </button>
    </template>
  </vue-modal>
</template>
<script>

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    tittleRows: "Permisos Generales",
    form: [],
    itemCopy: null,
  }),
  async mounted() {
    if (this.item) {
      this.itemCopy = {...this.item};
      await this.getRolePG();
      await this.getPG();
    }
  },
  computed: {
    rows(){
      return this.$store.getters.permisosGenerales.rows.map((i)=>{
        let findAvailable = this.mapRolePermisosGenerales.find((data)=> data.permiso_general_id == i.id)?.available || false
        return {
        id: i.id,
        mask: i.permiso_general_name,
        available: findAvailable
      }}
    )},
    comparador(){
      if (this.$store.getters.rolePermisosGenerales.data === null) return null
      return this.$store.getters.rolePermisosGenerales.rows
    },
    mapRolePermisosGenerales(){
      if (this.$store.getters.rolePermisosGenerales.data === null) return []
      return this.$store.getters.rolePermisosGenerales.rows.map(x=>({
        permiso_general_id: x.permiso_general_id,
        available: true
      }))
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getPG(){
      this.$store.dispatch("getPermisosGenerales");
    },
    getRolePG(){
      const id = this.itemCopy.id
      this.$store
          .dispatch("getRolePermisosGenerales", id);
    },
    onCheck(obj){
      const index = this.form.findIndex((x)=> (x.rowId === obj.rowId))
      if (index != -1){
        this.form[index] = {...this.form[index],checked:obj.checked}
      }else{
        this.form.push(obj)
      }  
    },
    saveRolePG() {
      // VAMOS A EXPLICAR COMO FUNCIONA ESTO

      const id = this.item.id
      let data = []

      // Si NO hay PG antiguos, pusheo los nuevos PG con cheked TRUE
      // Sino pusheoo los PG antiguos
        if (!this.comparador){
          data = this.form.filter((x)=> x.checked)
          data = data.map((x)=>({
            permiso_general_id: x.rowId,
          }))  
        }else{
          data = this.comparador
        }

        // Si HAY nuevos PG y si HAY viejos PG
        // Remuevo los PG con cheked FALSE y agrego los PG con cheked TRUE
        if (this.form.length && this.comparador) {
          this.form = this.form.map((x)=>({
            permiso_general_id: x.rowId,
            checked: x.checked
          }))
          this.form.forEach(n => {
            const index = this.comparador.findIndex(v => v.permiso_general_id === n.permiso_general_id);
             if(index === -1){
              if (n.checked){
                data.push({permiso_general_id: n.permiso_general_id})
                }
              }else{
                if (!n.checked){
                  data.splice(index,1)
                }
              } 
          })
        } 
        // Si elmino todos los PG devuelvo null (la DB elimina los PG al devolver null)
        // Sino mapeo los PG y envio a la DB
        if (!data.length){
          data = null
        }else{
          data = data.map((x)=>({
            permisoGeneralId: x.permiso_general_id,
          }))
        }

      const form = {data}
      this.$store
          .dispatch("updateRolePermisosGenerales", {id,form}).then(() => {
          this.close()
        });
        this.$emit('saved')
    },
  }
};
</script>
<style></style>
