<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Editar Nombre de Rol</template>
    <template #body>
      <form @submit.prevent="saveRoleName">
          <div class="card-body">
            <FormText
              label="Nombre del Rol"
              icon="fa-solid fa-chess"
              v-model="itemCopy.role_name"
              disabled
            />
            <FormText
                label="Nuevo Nombre del Rol"
                icon="fa-solid fa-paragraph"
                v-model="form.role_name"
              />
          </div>
        </form> 
    </template>
    <template #footer>
      <button class="btn btn-primary" type="submit" @click="saveRoleName">
        Actualizar
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      role_name: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.itemCopy = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveRoleName() {
      const id = this.itemCopy.id
      const form = {
        role_name: this.form.role_name,
      }
      this.$store
          .dispatch("updateRole", {id,form}).then(() => {
          this.close()
        });
        this.$emit('saved')
    },
  },
};
</script>
<style></style>
